import { configureStore } from '@reduxjs/toolkit';
import getInfoReducer from './userSlice';
import counterSlice from './counterSlice';
import createArchiveStateSlice from "./createArchiveStateSlice";
import uiReducer from './uiSlice';
import chatReducer from './chatSlice';
import unreadMessagesReducer from './unreadMessagesSlice';

export default configureStore({
    reducer: {
        userInfo: getInfoReducer, 
        counter: counterSlice,
        createArchiveStateSlice: createArchiveStateSlice,
        ui: uiReducer,
        chat: chatReducer,
        unreadMessages: unreadMessagesReducer,
    }
})