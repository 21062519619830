import React, { useState, useEffect } from 'react';
import { Select, Table, Button } from 'antd';
import { useHttp } from '../../hooks/http.hook';
const { Option } = Select;
import LicenseExtensionModal from "./LicenseExtensionModal/LicenseExtensionModal"

const LicenseExtension = () => {
  const [users, setUsers] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [armyPosts, setArmyPosts] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [selectedArmyPost, setSelectedArmyPost] = useState();
  const [devices, setDevices] = useState([]);
  const { request } = useHttp();
  

  useEffect(() => {
    request('/api/users/getList').then(response => setUsers(response));
    request('/api/device/getDepartmentsAndArmyPosts')
    .then(response => {
      setDepartments(response.departments);
      setArmyPosts(response.armyPosts);
    });
  }, []);

  const handleGetDevices = () => {
    const params = {
      user_id: selectedUser,
      department: selectedDepartment,
      army_post: selectedArmyPost
    };

    request('/api/device/getDevicesToExtendLicense', 'POST', params).then(response => {
      setDevices(response)
      setSelectedUser(undefined);
      setSelectedDepartment(undefined);
      setSelectedArmyPost(undefined);
    });
  };

  const columns = [
    { title: 'Android ID', dataIndex: 'device_id', key: 'device_id' },
    { 
      title: 'Военная часть', 
      dataIndex: 'army_post', 
      key: 'army_post',
      render: (text, record) => record.army_post || '-'
    },
    { 
      title: 'Ведомство', 
      dataIndex: 'department', 
      key: 'department',
      render: (text, record) => record.department || '-'
    },
    { 
      title: 'Ответственный', 
      dataIndex: ['user', 'name'], 
      key: 'user_name',
      render: (text, record) => record.user ? (record.user.name ? record.user.name : record.user_id) : '—'
    },
  ];

  return (
    <div>
      <div style={{ margin: "24px 0px 16px"}}>
        <Select
          style={{ width: '33%', marginRight: "1%" }}
          placeholder="Военная часть"
          value={selectedArmyPost}
          onChange={setSelectedArmyPost}
          showSearch
        >
          {armyPosts.map(armyPost => (
            <Option key={armyPost} value={armyPost}>{armyPost}</Option>
          ))}
        </Select>
        <Select
          style={{ width: '33%', marginRight: "1%" }}
          placeholder="Ведомство"
          value={selectedDepartment}
          onChange={setSelectedDepartment}
          showSearch

        >
          {departments.map(department => (
            <Option key={department} value={department}>{department}</Option>
          ))}
        </Select>
        <Select
          style={{ width: '32%' }}
          placeholder="Пользователь"
          value={selectedUser}
          onChange={setSelectedUser}
          showSearch
        >
          {users.map(user => (
            <Option key={user.id} value={user.id}>{user.name}</Option>
          ))}
        </Select>
      </div>
      <Table
        columns={columns}
        dataSource={devices}
        rowKey="device_id"
        scroll={{ y: 580 }}
        style={{ marginTop: 16, marginBottom: 16 }}
        pagination={false}
      />
      <Button
        type="primary"
        onClick={handleGetDevices}
        disabled={!selectedUser && !selectedDepartment && !selectedArmyPost}
      >
        Получить список
      </Button>
      <LicenseExtensionModal 
        devices={devices}
        setDevices={setDevices}
      />
    </div>
  );
};

export default LicenseExtension;
