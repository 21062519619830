import React from 'react'
import LicenseExtension from "../../components/LicenseExtension/LicenseExtension"
import './index.css'

const LicenseExtensionPage = () => {
  return (
    <div className='LicenseExtensionWrapper'>
        <LicenseExtension />
    </div>
  )
}

export default LicenseExtensionPage