import React, { useState, useEffect } from 'react';
import { Button, Table, Form, Input, notification, Radio, Space } from 'antd';

import './index.css';
import CreateForm from '../../pages/chartPage/CreateForm/CreateForm';
import getFiltersInColumn from '../../utils/filteredColumns';
import { useResize } from '../../utils/use-resize';
import { useHttp } from '../../hooks/http.hook';

import { DeleteOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import MapGetterForm from '../../pages/chartPage/MapGetterForm/MapGetterForm';

const ChartMapTable = () => {

	const { request } = useHttp();
	const [form] = Form.useForm();
	const [api, contextHodler] = notification.useNotification();
	const [data, setData] = useState();
	const [dataMbtiles, setDataMbtiles] = useState();
	const [updatedData, setUpdatedData] = useState();
	const [nameBtn, setNameBtn] = useState(null);
	const [clickRowId, setClickRowId] = useState();
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [totalData, setTotalData] = useState(10);
	const [totalDataMbtiles, setTotalDataMbtiles] = useState(10);
	const [loading, setLoading] = useState(false);

	const resize = useResize();
	const [mbtiles, setMbtiles] = useState('.hgt');

	const plainOptions = [
		{
			label: '.hgt',
			value: '.hgt',
		},
		{
			label: '.mbtiles',
			value: '.mbtiles',
		}
	];


	const columns = isMobile ?
		[
			{
				title: 'Название',
				dataIndex: 'name',
				key: 'name',
				// width: 150,
				filterDropDown: ({ setSelectedKeys, selectedKeys, confirm }) =>
					getFiltersInColumn(
						data,
						setSelectedKeys,
						selectedKeys,
						confirm,
						'id'
					),
				onFilter: (value, record) => {
					if (value === 'нет данных' && !record.id) {
						return record.id;
					} else {
						return record.id?.includes(value);
					}
				},
				sorter: (a, b) => a.name?.localeCompare(b.name),
				render: (text, record) => {
					return (
						<>
							{clickRowId === record.id &&
								'device_id' === nameBtn ? (
								<>
									<Form.Item name={'name'}>
										<Input
											className="tableInput"
											ref={(input) => input && input.focus()}
											onBlur={() => {
												setClickRowId();
												setNameBtn();
											}}
											value={text}
											style={{ textAlign: 'center' }}
										/>
									</Form.Item>
								</>
							) : (
								<>
									<div
										style={{
											height: '25px',
										}}
										onClick={() => {
											setClickRowId(record.id);
											setNameBtn('device_id');
										}}
									>{text}</div>
								</>
							)}
						</>
					)
				}
			},
			{
				title: 'Действия',
				dataIndex: 'action',
				key: 'action',
				render: (text, record) => {
					return (
						<div className='ChartPage__fields'>
							<ol style={{paddingLeft: 0}}>
							<Space direction='vertical' size="middle">
								<li style={{ listStyleType: 'none' }}>
									<Button
										type='primary'
										icon={<DeleteOutlined />}
										onClick={() => {
											deleteCurrentFile(record.id);
										}}
										style={{width: '100%', position: 'relative'}}
										danger
									>
										Удалить поле
									</Button>
								</li>
								<li style={{ listStyleType: 'none' }}>
									<Button type="default"
										icon={<DownloadOutlined />}
										onClick={() => {
											downloadCurrentFile(record.name);
										}}
										style={{width: '100%', position: 'relative'}}>
										Скачать
									</Button>
								</li>
								</Space>
							</ol>


						</div>
					)
				}
			}
		]
		:
		[
			{
				title: 'id',
				dataIndex: 'id',
				key: 'id',
				width: 150,
				filterDropDown: ({ setSelectedKeys, selectedKeys, confirm }) =>
					getFiltersInColumn(
						data,
						setSelectedKeys,
						selectedKeys,
						confirm,
						'id'
					),
				onFilter: (value, record) => {
					if (value === 'нет данных' && !record.id) {
						return record.id;
					} else {
						return record.id?.includes(value);
					}
				},
				sorter: (a, b) => a.id - b.id,
				render: (text, record) => {
					return record.id;
				},
			},
			{
				title: 'Имя файла',
				dataIndex: 'name',
				key: 'name',
				width: 150,
				filterDropDown: ({ setSelectedKeys, selectedKeys, confirm }) =>
					getFiltersInColumn(
						data,
						setSelectedKeys,
						selectedKeys,
						confirm,
						'name'
					),
				onFilter: (value, record) => {
					if (value === 'нет данных' && !record.name) {
						return !record.name;
					} else {
						return record.name?.includes(value);
					}
				},
				sorter: (a, b) => a.name?.localeCompare(b.name),
				render: (text, record) => {
					return (
						<>
							{clickRowId === record.id &&
								'device_id' === nameBtn ? (
								<>
									<Form.Item name={'name'}>
										<Input
											className="tableInput"
											ref={(input) => input && input.focus()}
											onBlur={() => {
												setClickRowId();
												setNameBtn();
											}}
											value={text}
											style={{ textAlign: 'center' }}
										/>
									</Form.Item>
								</>
							) : (
								<>
									<div
										style={{
											height: '25px',
										}}
										onClick={() => {
											setClickRowId(record.id);
											setNameBtn('device_id');
										}}
									>{text}</div>
								</>
							)}
						</>
					);
				},
			},
			{
				title: 'Путь к файлу',
				dataIndex: 'path',
				key: 'path',
				width: 150,
				editeble: true,
				filterDropDown: ({ setSelectedKeys, selectedKeys, confirm }) =>
					getFiltersInColumn(
						data,
						setSelectedKeys,
						selectedKeys,
						confirm,
						'path'
					),
				onFilter: (value, record) => {
					if (value === 'нет данных' && !record.id) {
						return !record.path;
					} else {
						return record.path?.includes(value);
					}
				},
				sorter: (a, b) => a.path?.localeCompare(b.path),
				render: (text, record) => {
					return record.path;
				},
			},
			{
				title: 'action',
				dataIndex: 'action',
				key: 'action',
				width: 150,
				render: (text, record) => {
					return (
						<div className='ChartPage__fields'>
							<Space>
								<Button type="submit"
									icon={<DownloadOutlined />}
									onClick={() => {
										downloadCurrentFile(record.name);
									}}>
									Скачать карту
								</Button>
								<Button
									type='primary'
									icon={<DeleteOutlined />}
									onClick={() => {
										deleteCurrentFile(record.id);
									}}
								>
									Удалить поле
								</Button>
							</Space>
						</div>
					)
				}
			}
		];




	useEffect(() => {
		fetchListChartPage();
	}, []);

	useEffect(() => {
		if (updatedData) {
			const newData = data?.map((item) => {
				if (item.id === updatedData.id) {
					return { ...item, [updatedData.nameKey]: updatedData.values };
				} else {
					return item;
				}
			});
			setData(newData);
			setUpdatedData(null);
		}
	}, [updatedData]);

	const infoNotification = (text) => {
		api.info({
			message: `Уведомление`,
			description: text,
			placement: 'topRight',
		})
	}

	const downloadCurrentFile = async (filename) => {
		try {
			mbtiles === '.mbtiles' ? await window.open(`api/app/map/download/${filename}?mbtiles=true`) : await window.open(`api/app/map/download/${filename}`)
			// const resData = await request(`api/app/map/download/${filename}`, `GET`);
		} catch (err) {
			console.log("Error inside downloadCurrentFile ", err);
		}
	}

	const openNotification = (custom) => {
		const key = `open${Date.now()}`;
		const btn = (
			<Button type="primary" size="small" onClick={() => notification.close(key)}>
				Хорошо
			</Button>
		);
		notification.open({
			message: custom,
			btn,
			key,
		});
	};

	//* GET THE TABLE FIELDS -------------------------------------------
	const getAllFields = async (values) => {
		try {
			const resData = await request(`api/app/map_client`, `GET`);

			if (resData) {
				await fetchListChartPage();
			}
		} catch (error) {
			openNotification('Ошибка при получении списка файлов карт.');
			console.log('ERROR - onFinishModal - forwarder >>> ', error);
		}
	}

	//* DELETE DATA ----------------------------------------------------
	const deleteCurrentFile = async (id) => {
		try {
			await request(`api/app/map/${id}/${mbtiles}`, `POST`);
		} catch (error) {
			console.log('ERROR - onDELETE - forwarder>>>', error);
		} finally {
			fetchListChartPage();
		}
	}

	const fetchListChartPage = async () => {
		try {
			setLoading(true);
			request(`api/app/map_client`)
				.then(async (resData) => {
					const resDataMbtiles = await request(`api/app/mbtiles_client`);

					setTotalData(resData.count);
					setTotalDataMbtiles(resDataMbtiles.count)

					setData(resData.rows.map((el, i) => ({ ...el, key: i + 1 })))
					setDataMbtiles(resDataMbtiles.rows.map((el, i) => ({ ...el, key: i + 1 })));
				})
				.finally(() => setLoading(false));
		} catch (error) {
			console.log('error-fetchDataChartPage >>>', error);
		}
	};

	const onFinish = async (values) => {
		try {
			await request(`api/app/map_client`, 'GET');

			await fetchListChartPage();
			setClickRowId();
			setNameBtn();
			form.resetFields();
		} catch (error) {
			console.log('error-getDeleteCell >>>', error, error.message);
		}

		console.log('values', values);
		try {
			await request(`api/app/map`, 'PATCH', {
				id: clickRowId,
				name: values['name'],
				path: values['path'],
			});

			await fetchListChartPage();
			setClickRowId();
			setNameBtn();
			form.resetFields();
		} catch (error) {
			console.log('error-getDeleteCell >>>', error, error.message);
		}
	};

	const onChangeRadio = ({ target: { value } }) => {
		setMbtiles(value);
	}

	return (
		<>
			{contextHodler}
			<div className='ChartPage__header'>
				<MapGetterForm infoNotification={infoNotification}/>
				{mbtiles === '.hgt' ? <CreateForm type='.hgt' getAllFields={getAllFields} /> : <CreateForm type='.mbtiles' getAllFields={getAllFields} />}
			</div>
			<Radio.Group
				style={{ marginBottom: '2%' }}
				options={plainOptions}
				onChange={onChangeRadio}
				value={mbtiles}
				buttonStyle="solid"
			/>
			{
				mbtiles === '.hgt' ? <Form form={form} onFinish={onFinish}>
					<Table
						loading={loading}
						columns={columns}
						dataSource={data}
						pagination={{
							position: ['bottomCenter'],
							showSizeChanger: false,
							current: page,
							pageSize: pageSize,
							total: totalData,
							onChange: async (page, pageSize) => {
								setLoading(true);
								setPage(page);
								setPageSize(pageSize);
								request(`api/app/map_client?offset=${page * 10}`)
									.then((res) => {
										setData(res.rows.map((el, i) => ({ ...el, key: i + 1 })))
									})
									.finally(() => setLoading(false))
							},
						}}
						bordered
					/>
				</Form> : <Form form={form} onFinish={onFinish}>
					<Table
						loading={loading}
						columns={columns}
						dataSource={dataMbtiles}
						pagination={{
							position: ['bottomCenter'],
							showSizeChanger: false,
							current: page,
							pageSize: pageSize,
							total: totalDataMbtiles,
							onChange: async (page, pageSize) => {
								setLoading(true);
								setPage(page);
								setPageSize(pageSize);
								request(`api/app/mbtiles_client?offset=${page * 10}`)
									.then((res) => {
										setData(res.rows.map((el, i) => ({ ...el, key: i + 1 })))
									})
									.finally(() => setLoading(false))
							},
						}}
						bordered
					/>
				</Form>
			}
		</>
	);

}

export default ChartMapTable;