import React, {useMemo, useState, useEffect, useRef, useCallback} from 'react';
import {CloudDownloadOutlined} from "@ant-design/icons";
import {Button, Modal, DatePicker, Switch, Space, notification} from "antd";
import { useHttp } from '../../hooks/http.hook';
import {setLoadingArchive} from "../../store/createArchiveStateSlice";
import {useSelector, useDispatch} from "react-redux";

const DownloadTranslationArchive = (params) => {
  const {request} = useHttp();
  const dateRef = useRef(null);
  const {RangePicker} = DatePicker;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [downloadAll, setCheckDownloadAll] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();

  const openNotification = (title, text) => {
    api.info({
      message: <b>{title}</b>,
      description: <b>{text}</b>,
      placement: 'topRight',
    })
  }

  const genRequestBody = (device_id, date) => {
    const resultObject = {device_id: device_id};
    if (date && date.length) {
      resultObject.startDate = new Date(date[0]);
      resultObject.endDate = new Date(date[1]);
    }
    return resultObject;
  }

  const handleOk = async () => {
    setIsModalOpen(false);
    dispatch(setLoadingArchive());
    request(`/api/streams/getArchiveById/true`,
      'POST',
      genRequestBody(params.device_id, dateRef.current))
        .then((res) => {
          if (res.status === 400) {
            openNotification("Архив трансляций", "К сожалению данных не найдено");
          } else {
            window.open(`/api/streams/download/${params.device_id}.zip`);
            openNotification("Архив трансляций", `Архив трансляций по устройству ${params.device_id} успешно сформирован!`);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          dispatch(setLoadingArchive())
        })
  }

  const handleCancel = () => {

    setIsModalOpen(false);
  }
  return (
    <>
      {contextHolder}
    <Button icon={<CloudDownloadOutlined/>} onClick={() => setIsModalOpen(true)} disabled={params.disabled}/>
    <Modal
      title={"Скачать архив за выбранный период"}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Space
        direction="vertical"
        size="middle"
        style={{
          display: 'flex'
        }}>
        <RangePicker
          placeholder={['Период: С', 'До']}
          variant="filled"
          disabled={downloadAll}
          onChange={(days) => dateRef.current = days}/>
        <Switch
          checkedChildren="Все"
          unCheckedChildren="По дате"
          onChange={checked => setCheckDownloadAll(checked)}/>
      </Space>
    </Modal>
</>
)
}

export default DownloadTranslationArchive;