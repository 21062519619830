import React, {useState} from 'react';
import {
  Button,
  Modal, Select,
  Form,
  notification, Input,
} from 'antd';
import {
  PlusCircleOutlined,
} from '@ant-design/icons'
import {useHttp} from '../../hooks/http.hook';

const StartNewChatModal = ({userInfo}) => {
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const {request} = useHttp();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [deviceData, setDeviceData] = useState([]);

  const openNotification = (message, description) => {
    api.info({
      message: message,
      description: description,
      placement: "topRight",
    })
  }

  const fetchListUsersTable = async () => {
    try {
      const resData = await request(`/api/users/getList`);
      return setData(resData
        .map((el, i) => ({...el, key: i + 1}))
        .filter((el) => el.id !== userInfo.userId))
    } catch (error) {
      console.log('error-fetchDataChartPage >>>', error);
    }
  };

  const showModal = async () => {
    await fetchListUsersTable();
    setOpen(true);
  }

  const handleOk = () => {
    form.validateFields()
      .then((values) => {
        form.resetFields()
        onFinish(values);
      })
      .catch((info) => {
        console.log('handleOk: Validate failed -> ', info);
        openNotification('Ошибка создания чата', info);
      });
    setOpen(false);
  }

  const onFinish = async (values) => {
    try {
      const {chatName, users, devices} = values;
      users.push(userInfo.userId);
      const response = await request(`/api/message/createChat`, 'POST', {
        chatName: chatName,
        users: users,
        devices: devices,
        admin: userInfo.userId,
      })
      if (response.error === false) {
        openNotification('Добавлен чат', response.message);
      }
    } catch (error) {
      console.log(`failed: ${error}`);
    }
  }

  const handleCancel = () => {
    setOpen(false);
  }

  return (
    <>
      {contextHolder}
      <Button
        type="dashed"
        size={'large'}
        icon={<PlusCircleOutlined />}
        onClick={showModal}
        style={{position: 'absolute', right: 0, marginRight: '10px', marginTop: '10px'}}
      />
      <Modal title='Новый чат' open={open} onOk={handleOk} onCancel={handleCancel}>
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
          initialValues={{
            remember: true,
          }}
          form={form}
          autoComplete="off"
        >
          <Form.Item
            label="Название чата"
            name="chatName">
            <Input />
          </Form.Item>
          <Form.Item
            label="Участники "
            name="users"
          >
            <Select
              showSearch
              mode='multiple'
              placeholder="Список участников"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={data?.map(({id, name, email}) => ({value: id, label: name || email}))}
            />
          </Form.Item>
          <Form.Item
            label="Устройства "
            name="devices"
          >
            <Select
              showSearch
              mode='multiple'
              placeholder="Список устройств"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={deviceData?.map(({device_id, call_sign}) => ({value: device_id, label: `${device_id}` || call_sign}))}
              disabled={true}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default StartNewChatModal;