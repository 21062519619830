import React from 'react';
import { Link } from 'react-router-dom';
import { Header } from 'antd/lib/layout/layout';
import NavBar from '../navBar/NavBar';
import logo from '../../images/storm_icon-icons.com_67503.png'

const DefaultHeader = () => {

  return (
    <Header style={{
      position: 'sticky',
      top: 0,
      zIndex: 100,
      width: '100%'
    }}>
      <Link className="logo" to={'/'}>
        <img alt="logo" width="40%" src={logo} />
      </Link>
      <NavBar />
    </Header>
  );
};

export default DefaultHeader;
