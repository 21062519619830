import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  LOGIN_ROUTE,
  MAIN_ROUTE,
  DIRECTORY_ROUTE,
  CHART_ROUTE,
  REG_REQUESTS_ROUTE,
  TRANSLATION,
  USERS,
  USERS_REQUESTS,
  MAPS_ROUTE,
  GROUP_TRANSLATIONS,
  OFFLINE_DIRECTORY_ROUTE, STATISTICS, MESSAGES_ROUTE, GEO, CALL_PAGE, ROOM_PAGE,UDP_TRANSLATION,
  LICENSE_EXTENSION,
} from './utils/consts';

import Auth from './pages/Auth';
import MainPage from './pages/mainPage/MainPage';
import DirectoryPage from './pages/directoryPage/DirectoryPage';
import ChartPage from './pages/chartPage/ChartPage';
import RequestsPage from './pages/requestsPage/RequestsPage';
import Translation from './pages/Translation/Translation';
import MapsPage from './pages/mapsPage/MapsPage';
import UsersList from './pages/Users/UsersList';
import NewUserRequestsList from './pages/UsersRequest/NewUserRequestsList';
import GroupTranslationsPage from './pages/groupTranslationsPage/GroupTranslationsPage';
import deviceOffline from './pages/deviceOffline/deviceOffline';
import Statistics from './pages/Statistics/Statistics';
import UDPTranslation from './pages/TranslationUDP/TranslationUDP';
import {useSelector} from "react-redux";
import MessagesPage from "./pages/messagesPage/MessagesPage";
import GeoDataPage from "./pages/geoDataPage/GeoDataPage";
import LicenseExtension from "./pages/licenseExtensionPage/LicenseExtensionPage"
import MainCallPage from "./pages/mainCallPage/MainCallPage";
import RoomPage from "./pages/roomPage/RoomPage";

export const useRoutes = (isAuthenticated) => {
  const user = useSelector(state => state.userInfo);
  const userAccesses = user.userState
  const isAdmin = user.isAdmin;

  const routList = [
    { path: MAIN_ROUTE, Component: MainPage, key: 'main'},
    { path: DIRECTORY_ROUTE, Component: DirectoryPage, key: 'forwarder'},
    { path: MESSAGES_ROUTE, Component: MessagesPage, key: 'messages'},
    { path: OFFLINE_DIRECTORY_ROUTE, Component: deviceOffline, key: 'device_offline'},
    { path: CHART_ROUTE, Component: ChartPage, key: 'app' },
    { path: REG_REQUESTS_ROUTE, Component: RequestsPage, key: 'registration_requests'},
    { path: TRANSLATION, Component: Translation, key: 'translation'},
    { path: MAPS_ROUTE, Component: MapsPage, key: 'maps'},
    { path: USERS, Component: UsersList, key: 'users' },
    { path: USERS_REQUESTS, Component: NewUserRequestsList, key: 'new_user_requests'},
    { path: GROUP_TRANSLATIONS, Component: GroupTranslationsPage, key: 'group_translations' },
    { path: STATISTICS, Component: Statistics, key: 'statistics' },
    { path: GEO, Component: GeoDataPage, key: 'geo_data' },
    { path: CALL_PAGE, Component: MainCallPage, key: 'call_page' },
    { path: ROOM_PAGE, Component: RoomPage, key: 'room_page' },
    { path: UDP_TRANSLATION, Component: UDPTranslation, key: 'udp_translation' },
    { path: LICENSE_EXTENSION, Component: LicenseExtension, key: 'license_extension' },
  ]

  if (isAuthenticated) {
    const routs = isAdmin ? routList : routList.filter(el => {
      if (el.key === 'main') {
        return true
      } else return userAccesses.responseAccesses ? userAccesses.responseAccesses[el.key] : ''
    })
    return (
      <Routes>
        <Route element={<Navigate to={MAIN_ROUTE} />} exact/>

        {routs.map(({ path, Component }) => {
          return <Route key={path} path={path} element={<Component />} exact/>;
        })}
      </Routes>
    );
  } else
    return (
      <Routes>
        <Route key={LOGIN_ROUTE} path="*" element={<Auth />} exact />
      </Routes>
    );

};
