import React, { useState, useEffect } from 'react';
import { useHttp } from '../../hooks/http.hook';
import { useSelector } from 'react-redux';
import {
	Button,
	Table,
	Form,
	Modal,
	Input,
	Select,
	message,
	Checkbox,
	Popconfirm,
	notification,
} from 'antd';
import {CrownOutlined, DeleteOutlined} from '@ant-design/icons';
import getFiltersInColumn from '../../utils/filteredColumns';
import { isMobile } from 'react-device-detect';

const UsersTable = () => {

	const { request } = useHttp();
	const [form] = Form.useForm();
	const [data, setData] = useState();
	const user_id = localStorage.userData ? JSON.parse(localStorage.userData).userId : null;
	const userInfo = useSelector(state => state.userInfo.userState);
	const { isAdmin, userState } = useSelector(state => state.userInfo);
	const [isAddNewUserModalOpen, setNewUserOpen] = useState(false);
	const [api, contextHolder] = notification.useNotification();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		fetchListUsersTable();
	}, [isAdmin]);

	const columns = isMobile ?
		[
			{
				title: 'Позывной',
				dataIndex: 'name',
				key: 'name',
				width: 150,
				filterDropDown: ({ setSelectedKeys, selectedKeys, confirm }) =>
					getFiltersInColumn(
						data,
						setSelectedKeys,
						selectedKeys,
						confirm,
						'name'
					),
				onFilter: (value, record) => {
					if (value === 'нет данных' && !record.name) {
						return !record.name;
					} else {
						return record.name?.includes(value);
					}
				},
				sorter: (a, b) => a.name?.localeCompare(b.name),
				render: (text, record) => {
					return record.name !== null ? record.name : record.email
				},
			},
			{
				title: 'Действия',
				dataIndex: 'todos',
				key: 'todos',
				width: 150,
				render: (text, record) => <><ChangeParamsModal record={record} /></>
			}
		]
		:
		[
			{
				title: 'Позывной',
				dataIndex: 'name',
				key: 'name',
				width: 150,
				filterDropDown: ({ setSelectedKeys, selectedKeys, confirm }) =>
					getFiltersInColumn(
						data,
						setSelectedKeys,
						selectedKeys,
						confirm,
						'name'
					),
				onFilter: (value, record) => {
					if (value === 'нет данных' && !record.name) {
						return !record.name;
					} else {
						return record.name?.includes(value);
					}
				},
				sorter: (a, b) => a.name?.localeCompare(b.name),
				render: (text, record) => { return record.name }
			},
			{
				title: 'Логин',
				dataIndex: 'email',
				key: 'email',
				width: 150,
				filterDropDown: ({ setSelectedKeys, selectedKeys, confirm }) =>
					getFiltersInColumn(
						data,
						setSelectedKeys,
						selectedKeys,
						confirm,
						'email'
					),
				onFilter: (value, record) => {
					if (value === 'нет данных' && !record.email) {
						return !record.email;
					} else {
						return record.email?.includes(value);
					}
				},
				sorter: (a, b) => a.email?.localeCompare(b.email),
				render: (text, record) => { return record.email }
			},
			{
				title: 'Телефон/Telegram',
				dataIndex: 'tel',
				key: 'tel',
				width: 150,
				filterDropDown: ({ setSelectedKeys, selectedKeys, confirm }) =>
					getFiltersInColumn(
						data,
						setSelectedKeys,
						selectedKeys,
						confirm,
						'tel'
					),
				onFilter: (value, record) => {
					if (value === 'нет данных' && !record.tel) {
						return !record.tel;
					} else {
						return record.tel?.includes(value);
					}
				},
				sorter: (a, b) => a.tel?.localeCompare(b.tel),
				render: (text, record) => { return record.tel }
			},
			{
				title: 'Ответственный',
				dataIndex: 'sub_id',
				key: 'sub_id',
				width: 150,
				render: (text, record) => {
					const result = data.filter(element => {
						if (record.sub_id === element.id) {
							return element
						}
					});
					return result[0] ? `${result[0].name ? result[0].name : 'Отсутствует'} (${result[0].email})` : `${userInfo.name ? userInfo.name : 'Отсутствует'} (${userInfo.email})`
				}
			},
			{
				title: 'Действия',
				dataIndex: 'todos',
				key: 'todos',
				width: 50,
				fixed: 'right',
				render: (text, record) => <div style={{textAlign: 'center'}}><ChangeParamsModal record={record} /></div>
			}
		];

	const ChangeParamsModal = (params) => {
		const [isChangeParamsOpen, setChangeParamsOpen] = useState(false);
		const [createAdmin, setCreateAdmin] = useState(false);
		const [accessChanged, setAccessChanged] = useState(false);
		const [actualAccesses, setActualAccesses] = useState(['falseFlag']);
		const [form] = Form.useForm();
		const { sub_id } = params.record;
		const checkBoxOptions = [
			{
				label: 'Список устройств',
				value: 'forwarder',
			},
			{
				label: 'Приложение',
				value: 'app',

			},
			{
				label: 'Сообщения',
				value: 'messages',
			},
			{
				label: 'Геоданные',
				value: 'geo_data',
			},
			{
				label: 'Карты',
				value: 'maps'
			},
			{
				label: 'Трансляции',
				value: 'translation',
			},
			{
				label: 'Пользователи',
				value: 'users',
			},
			{
				label: 'Групповые трансляции',
				value: 'group_translations'
			},
			{
				label: 'Оффлайн устройства',
				value: 'device_offline',
			},
			{
				label: 'Звонки',
				value: 'call_page'
			}
		].filter((el) => {
			if (isAdmin) {
				return true
			} else {
				if (userState.responseAccesses) {
					return userState.responseAccesses[el.value];
				}
			}
		});

		const openNotification = (text) => {
			api.info({
				message: `Администрация`,
				description: text,
				placement: 'topRight'
			})
		}

		const showModal = () => {
			setChangeParamsOpen(true);
		};

		const handleCancel = () => {
			setChangeParamsOpen(false);
		};

		const onFinish = async (values) => {
			if (createAdmin) {
				values.sub_id = 0;
				values.name = params.record.name;
				values.email = params.record.email;
				await request('/api/users/change_params', 'POST', { values });
			} else {
				setChangeParamsOpen(false);

				values.name = params.record.name;
				values.email = params.record.email;
				values.sub_id = values.sub_id ? values.sub_id : params.record.sub_id;

				await request('/api/users/change_params', 'POST', { values })
				if (accessChanged) {
					const resultJSON = {};
					actualAccesses.forEach((el) => {
						if (el === 'call_page') {
							resultJSON[el] = true;
							resultJSON['room_page'] = true;
						} else {
							resultJSON[el] = true
						}
					});
					await request('/api/users/set_accesses', 'POST', {
						user_id: params.record.id,
						actualAccesses: resultJSON,
					})
				}
			}

			fetchListUsersTable();
		};

		const getUserAccesses = () => {
			const accessesMap = [];

			data.forEach((el) => {
				if (el.id === params.record.id && el['accesses_table.accesses_field']) {
					const jsonAccesses = JSON.parse(el['accesses_table.accesses_field'])
					return Object.keys(jsonAccesses).forEach((item) => {
						accessesMap.push(item);
					})
				}
			});

			return accessesMap;
		}

		const onChangeCheckBox = (checkedValues) => {
			setAccessChanged(true);
			setActualAccesses(checkedValues);
		}


		const onChangeAdminCheckBox = (checkedValue) => {
			setCreateAdmin(checkedValue.target.checked);
		}

		const onChangeCircleOfTrustBox = async () => {
			const ids = await request(`/api/device/actual/${user_id}?fullList=true&idOnly=true`);
			const response = await request(`/api/device/assignCircleToTrust`, `POST`, {ids: ids.rows, currentUser: params.record.id});
			if (!response.error) {
				openNotification(`Теперь пользователь ${params.record.name || params.record.email} может просматривать трансляции ваших устройств!`)
			} else {
				openNotification(`Ошибка при попытке добавления пользователя ${params.record.name || params.record.email} в круг доверенных лиц!`);
			}
		}
		const handleOk = async () => {
			form.validateFields()
				.then((values) => {
					form.resetFields();
					onFinish(values);
				})
				.catch((info) => {
					console.log('Validate failed: ', info);
				});
		}

		const PopConfirmWrapper = (data) => {
			return (
				<Popconfirm
					title="Удаление пользователя"
					description="Вы уверены что хотите удалить пользователя?"
					onConfirm={handleDelete}
					okText="Да, я уверен"
					cancelText="Отмена">
					{
						params.record.id === user_id
						|| data.insideModal && params.record.sub_id === 0
							? <></> : data.insideModal ?
								<Button key={'delete'} danger>Удалить</Button>
								:
								<Button type='secondary' icon={<DeleteOutlined />}>
							</Button>}
				</Popconfirm>
			)
		}

		const handleDelete = async () => {
			try {
				const response = await request('api/user/' + params.record.id, `DELETE`);
			} catch (err) {
				console.log(`ERROR on handleDelete: ${err}`);
			}

			fetchListUsersTable();
		}
		return (
			<>
				{sub_id === 0 ?
					<>
					<Button type='secondary'
						icon={<CrownOutlined />}
						onClick={() => openNotification(`Невозможно изменить права администратора`)}></Button>
					{isAdmin && <PopConfirmWrapper />}
					</>
					:
					<Button type="primary" onClick={showModal}>
						Изменить
					</Button>}
				<Modal title="Изменение текущего пользователя"
					open={isChangeParamsOpen}
					footer={[
						<PopConfirmWrapper insideModal={true}/>,
						<Button key={'back'} onClick={handleCancel}>Отмена</Button>,
						<Button key={'ok'} onClick={handleOk}>Применить</Button>,
					]}
					onOk={handleOk}
					onCancel={handleCancel}
				>
					<Form
						name="basic"
						labelCol={{
							span: 8,
						}}
						wrapperCol={{
							span: 16,
						}}
						style={{
							maxWidth: 600,
						}}
						initialValues={{
							remember: true,
						}}
						form={form}
						autoComplete="off"
					>
						<Form.Item
							label="Позывной"
							name="name"
						>
							<Input defaultValue={params.record.name} disabled />
						</Form.Item>

						<Form.Item
							label="Почта"
							name="email"
						>
							<Input defaultValue={params.record.email} disabled />
						</Form.Item>

						<Form.Item
							label="Телефон/Telegram"
							name="tel"
						>
							<Input />
						</Form.Item>

						<Form.Item
							label="Ответственный"
							name="sub_id"
						>
							<Select
								showSearch
								placeholder="Выберите ответственного"
								optionFilterProp="children"
								filterOption={(input, option) =>
									(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
								}
								options={data?.map(({ id, name, email }) => ({ value: id, label: name || email }))}
								defaultValue={() => {
									const result = data.filter(element => {
										if (params.record.sub_id === element.id) {
											return element
										}
									});
									return result[0] ? `${result[0].name ? result[0].name : 'Отсутствует'}` : `${userInfo.name ? userInfo.name : 'Отсутствует'}`
								}}
							/>
						</Form.Item>

						{isAdmin ? <Form.Item label='Администрация'>
							<Checkbox onChange={onChangeAdminCheckBox}
								disabled={sub_id === 0}>Сделать администратором</Checkbox>
						</Form.Item> : null}

						<Form.Item label='Круг доверия'>
							<Popconfirm
								title="Круг доверия"
								description="Вы уверены, что хотите дать доступ к просмотру трансляций всех своих устройств?"
								onConfirm={onChangeCircleOfTrustBox}
								okText="Да, я уверен"
								cancelText="Отмена">
									<Button>Сделать доверенным к устройствам</Button>
							</Popconfirm>
						</Form.Item>

						<Form.Item
							label="Доступные разделы: ">
							<Checkbox.Group
								options={checkBoxOptions}
								onChange={onChangeCheckBox}
								defaultValue={getUserAccesses}
								style={{
									display: 'block',
									left: 5
								}}
								disabled={createAdmin || sub_id === 0}
							/>
						</Form.Item>
					</Form>
				</Modal>
			</>
		);
	}

	const AddNewUserModal = () => {
		const [form] = Form.useForm();
		const showModal = () => {
			setNewUserOpen(true);
		};

		const handleCancel = () => {
			setNewUserOpen(false);
		};

		const onFinish = (values) => {
			setNewUserOpen(false);
			sendNewUserRequest(values);
		};

		return (
			<>
				<Button type="primary" onClick={showModal} style={{ float: 'right', marginBlock: 10 }}>
					Добавить пользователя
				</Button>
				<Modal title="Добавление нового пользователя"
					open={isAddNewUserModalOpen}
					onOk={() => {
						form.validateFields()
							.then((values) => {
								form.resetFields();
								onFinish(values);
							})
							.catch((info) => {
								console.log('Validate failed: ', info);
							})
					}}
					onCancel={handleCancel}
				>
					<Form
						name="basic"
						labelCol={{
							span: 8,
						}}
						wrapperCol={{
							span: 16,
						}}
						style={{
							maxWidth: 600,
						}}
						initialValues={{
							remember: true,
						}}
						form={form}
						autoComplete="off"
					>
						<Form.Item
							label="Позывной"
							name="name"
							rules={[
								{
									required: true,
									message: 'Необходимо указать позывной!',
								},
							]}
						>
							<Input />
						</Form.Item>

						<Form.Item
							label="Почта"
							name="email"
							rules={[
								{
									required: true,
									message: 'Необходимо указать почту!',
								},
							]}
						>
							<Input />
						</Form.Item>

						<Form.Item
							label="Пароль"
							name="password"
							rules={[
								{
									min: 8,
									message: 'Длина пароля не менее 8 символов',
								},
								{
									required: true,
									message: 'Необходимо указать пароль!',
								},
							]}
						>
							<Input.Password />
						</Form.Item>

						<Form.Item
							label="Телефон/Telegram"
							name="tel"
						>
							<Input />
						</Form.Item>

						<Form.Item
							label="Ответственный"
							name="sub_id"
						>
							<Select
								showSearch
								placeholder="Выберите ответственного"
								optionFilterProp="children"
								filterOption={(input, option) =>
									(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
								}
								options={data?.map(({ id, name, email }) => ({ value: id, label: name || email }))}
							/>
						</Form.Item>

						<Form.Item
							wrapperCol={{
								offset: 8,
								span: 16,
							}}
						>
						</Form.Item>
					</Form>
				</Modal>
			</>
		);
	}

	const sendNewUserRequest = async (values) => {
		values.sub_id = values.sub_id ? values.sub_id : user_id;
		if (isAdmin) {
			const response = await request('api/new_user_request/fast-approve', 'POST', values);
			if (!response.error) {
				message.success(response);
			} else {
				message.error(response.message);
			}
		} else {
			const response = await request('api/new_user_request/create_req', 'POST', values);
			if (!response.error) {
				message.success(response);
			} else {
				message.error(response.message);
			}
		}

		fetchListUsersTable();
	}

	//* GET ALL USERS
	const fetchListUsersTable = async () => {
		try {
			setLoading(true);
			request(`api/users/getList`)
				.then((resData) => {
					setData(resData.map((el, i) => ({ ...el, key: i + 1 })))
				})
				.finally(() => setLoading(false));
		} catch (error) {
			console.log('error-fetchDataChartPage >>>', error);
		}
	};

	const onFinish = async () => {
		try {
			request('/api/users/getList');
			await fetchListUsersTable();
			form.resetFields();
		} catch (error) {
			console.log('error-getDeleteCell >>>', error, error.message);
		}
	};

	return (
		<>
			{contextHolder}
			<div className='regreq_pos'>
				<AddNewUserModal />
				<Form form={form} onFinish={onFinish}>
					<Table
						loading={loading}
						columns={columns}
						dataSource={data}
						pagination={false}
						scroll={isMobile ? {} : {x: 2500}}
						bordered
					/>
				</Form>
			</div>
		</>
	);

}

export default UsersTable;
