import { useEffect, useRef, useCallback, useState } from 'react';
import useStateWithCallback from './useStateWithCallback';
import { on, emit, removeAllListeners } from '../socket';
import { useSelector } from 'react-redux';
import ACTIONS from '../utils/socketActions';


export const LOCAL_VIDEO = 'LOCAL_VIDEO';


export default function useWebRTC(roomID) {
  const CameraFacingMode = {
    FRONT: 'user',
    BACK: 'environment'
  }
  const userInfo = useSelector((state) => state.userInfo.userState);
  const [clients, updateClients] = useStateWithCallback([]);
  const [isVideoEnabled, setVideoEnabled] = useState(true);
  const [isVideoSourceExist, setIsVideoSourceExist] = useState(false);
  const [isCameraCanSwitch, setIsCameraCanSwitch] = useState(false);
  const [isAudioEnabled, setAudioEnabled] = useState(true);
  const [isAudioSourceExist, setIsAudioSourceExist] = useState(false);
  const [isScreenSharing, setScreenSharing] = useState(false);
  let facingMode = CameraFacingMode.FRONT;
  const newConstraints = {
    video: {
      facingMode: facingMode === CameraFacingMode.FRONT ? CameraFacingMode.BACK : CameraFacingMode.FRONT,
      width: { ideal: 1280 },
      height: { ideal: 720 }
    },
    audio: isAudioEnabled
  };

  const addNewClient = useCallback((newClient, cb) => {
    updateClients(list => {
      if (!list.some(c => c.peerID === newClient.peerID)) {
        return [...list, newClient];
      }
      return list;
    }, cb);

  }, [clients, updateClients]);

  const peerConnections = useRef({});
  const localMediaStream = useRef(null);
  const screenMediaStream = useRef(null);
  const peerMediaElements = useRef({
    [LOCAL_VIDEO]: null
  });

  useEffect(() => {
    async function handleNewPeer({ peerID, createOffer }) {
      if (peerID in peerConnections.current) {
        return console.warn(`Already connected to peer ${peerID}`);
      }
      const response = await fetch('/api/turnInfo/getCredentials', {
        method: 'GET',
        headers: {
          'apikey': process.env.REACT_APP_TURN_SECRET
        }
      });
      const jsonRes = await response.json();
      peerConnections.current[peerID] = new RTCPeerConnection({
        iceServers: [{ urls: 'stun:turn.stormapi.su:3479' }, {
          urls: 'turn:turn.stormapi.su:3479',
          username: jsonRes.username,
          credential: jsonRes.password
        }]
      });

      peerConnections.current[peerID].onicecandidate = event => {
        if (event.candidate) {
          emit(ACTIONS.RELAY_ICE, {
            peerID,
            iceCandidate: event.candidate
          });
        }
      };

      let tracksNumber = 0;
      peerConnections.current[peerID].ontrack = ({ streams: [remoteStream] }) => {
        tracksNumber++;

        if (tracksNumber === 2) {
          tracksNumber = 0;
          const state = clientObject(peerID, isVideoEnabled);
          addNewClient(state, () => {
            if (peerMediaElements.current[state.peerID]) {
              peerMediaElements.current[state.peerID].srcObject = remoteStream;
            } else {
              // FIX LONG RENDER IN CASE OF MANY CLIENTS
              let settled = false;
              const interval = setInterval(() => {
                if (peerMediaElements.current[state.peerID]) {
                  peerMediaElements.current[state.peerID].srcObject = remoteStream;
                  settled = true;
                }

                if (settled) {
                  clearInterval(interval);
                }
              }, 1000);
            }
          });
        }
      };

      localMediaStream.current.getTracks().forEach(track => {
        peerConnections.current[peerID].addTrack(track, localMediaStream.current);
      });

      if (createOffer) {
        const offer = await peerConnections.current[peerID].createOffer();

        await peerConnections.current[peerID].setLocalDescription(offer);

        emit(ACTIONS.RELAY_SDP, {
          peerID,
          sessionDescription: offer
        });
      }
    }

    on(ACTIONS.ADD_PEER, handleNewPeer);

    return () => {
      removeAllListeners(ACTIONS.ADD_PEER);
    };
  }, []);

  useEffect(() => {
    on(ACTIONS.TOGGLE_VIDEO, ({ peerID, isVideoEnabled }) => {
      updateClients(clients.map(i => {
        if (i.peerID === peerID) {
          return { ...i, isVideoEnabled: isVideoEnabled };
        }
        return i;
      }));
    });


    return () => {
      removeAllListeners(ACTIONS.TOGGLE_VIDEO);
    };
  }, [clients, updateClients]);

  useEffect(() => {
    async function setRemoteMedia({ peerID, sessionDescription: remoteDescription }) {
      await peerConnections.current[peerID]?.setRemoteDescription(
        new RTCSessionDescription(remoteDescription)
      );

      if (remoteDescription.type === 'offer') {
        const answer = await peerConnections.current[peerID].createAnswer();
        await peerConnections.current[peerID].setLocalDescription(answer);

        emit(ACTIONS.RELAY_SDP, {
          peerID,
          sessionDescription: answer
        });
      }
    }

    on(ACTIONS.SESSION_DESCRIPTION, setRemoteMedia);

    return () => {
      removeAllListeners(ACTIONS.SESSION_DESCRIPTION);
    };
  }, []);

  useEffect(() => {
    on(ACTIONS.ICE_CANDIDATE, ({ peerID, iceCandidate }) => {
      peerConnections.current[peerID]?.addIceCandidate(
        new RTCIceCandidate(iceCandidate)
      );
    });

    return () => {
      removeAllListeners(ACTIONS.ICE_CANDIDATE);
    };
  }, []);

  useEffect(() => {
    const handleRemovePeer = ({ peerID }) => {
      if (peerConnections.current[peerID]) {
        peerConnections.current[peerID].close();
      }

      delete peerConnections.current[peerID];
      delete peerMediaElements.current[peerID];

      updateClients(list => list.filter(c => c.peerID !== peerID));
    };

    on(ACTIONS.REMOVE_PEER, handleRemovePeer);

    return () => {
      removeAllListeners(ACTIONS.REMOVE_PEER);
    };
  }, []);

  useEffect(() => {
    // Обновление видеопотока для локального пользователя
    if (peerMediaElements.current[LOCAL_VIDEO]) {
      if (isVideoEnabled) {
        peerMediaElements.current[LOCAL_VIDEO].srcObject = localMediaStream.current;
      } else {
        peerMediaElements.current[LOCAL_VIDEO].srcObject = null;
      }
    }
  }, [isVideoEnabled]);

  useEffect(() => {
    // Обновление видеопотоков для всех удаленных участников
    clients.forEach(client => {
      if (client.peerID !== LOCAL_VIDEO && peerMediaElements.current[client.peerID]) {
        const videoElement = peerMediaElements.current[client.peerID];

        if (client.isVideoEnabled) {
          const remoteStream = peerConnections.current[client.peerID]?.getRemoteStreams?.()[0];
          if (remoteStream) {
            videoElement.srcObject = remoteStream;
          } else {
            console.warn(`No remote stream available for client ${client.peerID}`);
          }
        } else {
          videoElement.srcObject = null;
        }
      }
    });
  }, [clients, peerConnections]);


  useEffect(() => { // Начало передачи данных и установка соединения происходит здесь!
    async function startCapture() {
      // Здесь нужно для начала получить доступно ли видео для пользователя и доступна ли передача звука?
      const audioDevices = await getDevices.audio();
      const videoDevices = await getDevices.video();
      setIsVideoSourceExist(!!videoDevices.length);
      setIsCameraCanSwitch(videoDevices.length > 1);
      setIsAudioSourceExist(!!audioDevices.length);
      localMediaStream.current = await navigator.mediaDevices.getUserMedia({
        audio: !!audioDevices.length,
        video: videoDevices.length ? {
          facingMode: { ideal: facingMode },
          width: { ideal: 1280 },
          height: { ideal: 720 }
        } : false,
      });
      const state = clientObject(LOCAL_VIDEO, isVideoEnabled);
      addNewClient(state, () => {
        const localVideoElement = peerMediaElements.current[LOCAL_VIDEO];

        if (localVideoElement) {
          localVideoElement.volume = 0;
          localVideoElement.srcObject = localMediaStream.current;
        }
      });
    }

    startCapture()
      .then(() => emit(ACTIONS.JOIN,
        {
          room: roomID,
          userId: userInfo.userId,
          callState: { peerID: Object.keys(peerConnections.current)[0], videoState: true, audioState: true }
        }))
      .catch(e => console.error('Error getting userMedia:', e));

    return () => {
      localMediaStream.current.getTracks().forEach(track => track.stop());
      if (screenMediaStream.current) {
        screenMediaStream.current.getTracks().forEach(track => track.stop());
      }
      if (peerMediaElements.current && peerMediaElements.current.length) {
        peerMediaElements.current.forEach(i => i.getTracks().forEach(track => track.stop()))
      }

      emit(ACTIONS.LEAVE);
    };
  }, [roomID]);

  const clientObject = (peerID, isVideoEnabled) => ({ peerID: peerID, isVideoEnabled: isVideoEnabled });

  const provideMediaRef = useCallback((id, node) => {
    peerMediaElements.current[id] = node;
  }, []);

  const toggleVideo = useCallback(async () => { // некорректно обновляет у себя при включении после выключения
    if (isVideoEnabled) {
      const currentVideoTrack = localMediaStream.current.getVideoTracks()[0];
      if (currentVideoTrack) currentVideoTrack.stop();
    } else {
      const bufferStream = await navigator.mediaDevices.getUserMedia(newConstraints);
      const newVideoTrack = bufferStream.getVideoTracks()[0];

      await replaceVideoTrackInPeerConnection(peerConnections, newVideoTrack);
      replaceVideoTrackInLocalStream(localMediaStream, newVideoTrack);
    }
    updateClients(list =>
      list.map(client => ({
        ...client,
        isVideoEnabled: client.peerID === LOCAL_VIDEO ? !isVideoEnabled : client.isVideoEnabled
      }))
    );


      setVideoEnabled(!isVideoEnabled);

      // Notify other peers about video state change
      emit(ACTIONS.TOGGLE_VIDEO, {
        peerID: Object.keys(peerConnections.current)[0],
        isVideoEnabled: !isVideoEnabled,
        userId: userInfo.userId,
        roomID: roomID
      });

    // });
  }, [localMediaStream, updateClients, peerConnections, isVideoEnabled, newConstraints]);

  const toggleAudio = useCallback(() => {
    if (localMediaStream.current.getAudioTracks().length > 0) {
      localMediaStream.current.getAudioTracks().forEach(track => {
        track.enabled = !track.enabled;
        setAudioEnabled(track.enabled);
      });
    } else {
      console.warn('Аудиотреки отсутствуют');
    }
  }, []);

  const getDevices = {
    video: async function() {
      const devices = await navigator.mediaDevices.enumerateDevices();
      return devices.filter(device => device.kind === 'videoinput');
    },
    audio: async function() {
      const devices = await navigator.mediaDevices.enumerateDevices();
      return devices.filter(device => device.kind === 'audioinput');
    }
  }

  const toggleFacingMode = async () => {
    facingMode = facingMode === CameraFacingMode.FRONT ? CameraFacingMode.BACK : CameraFacingMode.FRONT;
    return switchCameraFacingMode(localMediaStream, peerConnections, facingMode);
  }


  /**
   * Switch to the new facingMode without stopping the existing stream and hopefully without re-negotiating RTC connection
   */
  const switchCameraFacingMode = async (localStream, peerConnection, facingMode) => {
    const oldVideoTrack = localStream.current.getVideoTracks()[0];

    if (oldVideoTrack) {
      oldVideoTrack.stop();
    }

    const bufferStream = await navigator.mediaDevices.getUserMedia(newConstraints);
    const newVideoTrack = bufferStream.getVideoTracks()[0];

    await replaceVideoTrackInPeerConnection(peerConnection, newVideoTrack);
    replaceVideoTrackInLocalStream(localStream, newVideoTrack);

  }

  const replaceVideoTrackInLocalStream = (localStream, track) => {
    const oldVideoTrack = localStream.current.getVideoTracks()[0];

    localStream.current.removeTrack(oldVideoTrack);
    localStream.current.addTrack(track);
  }

  const replaceVideoTrackInPeerConnection = async (connection, track) => {
    const sender = await connection.current[Object.keys(connection.current)[0]]?.getSenders().find(
      s => s.track.kind === track.kind
    );

    return sender?.replaceTrack(track);
  }

  const toggleScreenSharing = useCallback(async () => {
    if (isScreenSharing) {
      screenMediaStream.current.getTracks().forEach(track => track.stop());
      setScreenSharing(false);
      setVideoEnabled(true);
      updateClients(list =>
        list.map(client => ({
          ...client,
          isVideoEnabled: client.peerID === LOCAL_VIDEO ? true : client.isVideoEnabled
        }))
      );
      const bufferStream = await navigator.mediaDevices.getUserMedia(newConstraints);
      const newVideoTrack = bufferStream.getVideoTracks()[0];

      await replaceVideoTrackInPeerConnection(peerConnections, newVideoTrack);
      replaceVideoTrackInLocalStream(localMediaStream, newVideoTrack);
    } else {
      try {
        screenMediaStream.current = await navigator.mediaDevices.getDisplayMedia({
          video: true,
          audio: true,
        });
        const screenTrack = screenMediaStream.current.getVideoTracks()[0];
        await replaceVideoTrackInPeerConnection(peerConnections, screenTrack);
        replaceVideoTrackInLocalStream(localMediaStream, screenTrack);
        setScreenSharing(true);
      } catch (e) {
        console.error('Error sharing screen:', e);
      }
    }
  }, [isScreenSharing, peerConnections, localMediaStream, newConstraints]);

  return {
    clients,
    provideMediaRef,
    toggleVideo,
    toggleAudio,
    isCameraCanSwitch,
    toggleFacingMode,
    toggleScreenSharing,
    isVideoEnabled,
    isVideoSourceExist,
    isAudioEnabled,
    isAudioSourceExist,
    isScreenSharing
  };
}