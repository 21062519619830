import React, {useEffect, useState} from 'react';
import {
  Button,
  Modal, Select,
  Form,
  notification,
} from 'antd';
import {
  UserAddOutlined,
} from '@ant-design/icons'
import {useHttp} from '../../hooks/http.hook';

const TrustUserGroup = ({device_id, readOnlySubs, fetchListDevices, actualSegment, userInfo, isMobile, trustUserModalOpen, setTrustUserModalOpen, showTrustUserModal, usersData}) => {
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const {request} = useHttp();
  const [readOnlySubsArray, setReadOnlySubsArray] = useState([])
  const [trustUserMobileOpen, setTrustUserMobileOpen] = useState(false)

  useEffect(() => {
    form.setFieldsValue({subsArray: JSON.parse(readOnlySubs)?.userIds || []})
  }, [trustUserModalOpen, readOnlySubs])

  useEffect(() => {
    setReadOnlySubsArray(JSON.parse(readOnlySubs)?.userIds || [])
  }, [readOnlySubs])

  const openNotification = (message, description) => {
    api.info({
      message: message,
      description: description,
      placement: "topRight",
    })
  }

  const handleOk = () => {
    form.validateFields()
      .then((values) => {
        form.resetFields();
        onFinish(values);
      })
      .catch((info) => {
        console.log('TRUSTUSERS: Validate failed -> ', info);
        openNotification('Ошибка обновления устройства', info);
      });
    setTrustUserModalOpen(false);
    setTrustUserMobileOpen(false)
  }

  const onFinish = async (values) => {
    try {
      const {subsArray} = values;
      const resData = await request('/api/device/changeReadOnlySubs', 'POST', {
        device_id: device_id,
        subsArray: subsArray,
      })
      if (resData.status >= 200) {
        openNotification('Обновление устройства', resData.message);
      }
    } catch (error) {
      console.log(`failed: ${error}`);
    } finally {
      fetchListDevices(actualSegment);
    }
  }

  const handleCancel = () => {
    setTrustUserModalOpen(false);
    setTrustUserMobileOpen(false)
  }

  return (
    <>
      {contextHolder}
      {isMobile &&
        <Button type="default" size='small' style={{width: '100%', position: 'relative'}} onClick={setTrustUserMobileOpen}>Доступы</Button>}
      <Modal title={`Доступы к просмотру устройства ${device_id}`} open={trustUserModalOpen || trustUserMobileOpen} onOk={handleOk} onCancel={handleCancel}>
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
          initialValues={{
            remember: true,
          }}
          form={form}
          autoComplete="off"
        >
          <Form.Item
            label="Назначить "
            name="subsArray"
          >
            <Select
              showSearch
              mode='multiple'
              placeholder="Список наблюдателей"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={usersData?.map(({id, name, email}) => ({value: id, label: name || email}))}
              defaultValue={usersData?.map((item, i) => {
                if (readOnlySubsArray.includes(item.id)) {
                  return {label: item.name || item.email, value: item.id}
                }
              }).filter(item => item !== undefined)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default TrustUserGroup;