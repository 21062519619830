import { useParams, useNavigate } from 'react-router-dom';
import useWebRTC, { LOCAL_VIDEO } from '../../hooks/useWebRTC';
import { Avatar, Button, Space } from 'antd';
import {
  AudioMutedOutlined,
  AudioOutlined,
  PhoneOutlined,
  RetweetOutlined, ShakeOutlined,
  VideoCameraOutlined
} from '@ant-design/icons';
import { useEffect, useMemo } from 'react';
import { rgb } from 'd3-color';

function layout(clientsNumber = 1) {
  const pairs = Array.from({ length: clientsNumber })
    .reduce((acc, next, index, arr) => {
      if (index % 2 === 0) {
        acc.push(arr.slice(index, index + 2));
      }

      return acc;
    }, []);

  const rowsNumber = pairs.length;
  const height = `${100 / rowsNumber}%`;

  return pairs.map((row, index, arr) => {

    if (index === arr.length - 1 && row.length === 1) {
      return [{
        width: '100%',
        height
      }];
    }

    return row.map(() => ({
      width: '50%',
      height
    }));
  }).flat();
}

export default function Room() {
  const history = useNavigate();
  const { id: roomID } = useParams();
  const { clients,
    provideMediaRef,
    toggleAudio,
    toggleVideo,
    toggleScreenSharing,
    isAudioEnabled,
    isVideoEnabled,
    isScreenSharing,
    toggleFacingMode,
    isVideoSourceExist,
    isAudioSourceExist,
    isCameraCanSwitch,
  } = useWebRTC(roomID);

  const videoLayout = layout(clients.filter(item => item.isVideoEnabled).length);

  const genRGB = () => {
    return rgb(`#${Math.floor(Math.random()*16777215).toString(16)}`)
  }

  return (
    <>
      <Space size={16} wrap>
        <Avatar.Group>
          {clients.map((client, index) => (<Avatar
            key={index}
            shape="square"
            size={64}
            style={{backgroundColor: genRGB()}}>{client.peerID}</Avatar>))}
        </Avatar.Group>
      </Space>
      <Space align="end" style={{
        position: 'fixed', zIndex: 9999,
        bottom: 15,
        right: '5vh',
        paddingBottom: '5vh',
        margin: '0 auto'
      }}>
        {isCameraCanSwitch && <Button shape={'circle'} type={'primary'} icon={<RetweetOutlined />}
                onClick={toggleFacingMode} />}
        <Button shape={'default'} type={'primary'} icon={<ShakeOutlined />}
                onClick={toggleScreenSharing} >{isScreenSharing ? 'Stop Sharing Screen' : 'Share Screen'}</Button>
        {isAudioSourceExist && <Button shape={'circle'} type={'primary'}
                icon={isAudioEnabled ? <AudioOutlined /> : <AudioMutedOutlined />}
                danger={!isAudioEnabled}
                onClick={toggleAudio} />}
        {isVideoSourceExist && <Button shape={'circle'}
                type={isVideoEnabled ? 'primary' : 'default'}
                icon={<VideoCameraOutlined />}
                onClick={toggleVideo} />}
        <Button shape={'circle'} type={'primary'} icon={<PhoneOutlined />}
                onClick={() => history('/call_page')}
                danger />
      </Space>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        height: '100vh'
      }}>
        {clients.filter(client => client.isVideoEnabled).map((peer, index) => {
            return (
              <div key={peer.peerID} style={videoLayout[index]} id={peer.peerID}>
                <video
                  width="100%"
                  height="100%"
                  ref={instance => {
                    provideMediaRef(peer.peerID, instance);
                  }}
                  autoPlay
                  playsInline
                  muted={peer.peerID === LOCAL_VIDEO}
                />
              </div>
            );
        })}
      </div>
    </>
  );
}