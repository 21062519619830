// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ForwarderTable__header {
  padding-bottom: 20px;
}

.EditGroupDevices__modal {
  order: -1;
  margin-right: auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/forwarderTable/index.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;AACtB;;AAEA;EACE,SAAS;EACT,kBAAkB;AACpB","sourcesContent":[".ForwarderTable__header {\n  padding-bottom: 20px;\n}\n\n.EditGroupDevices__modal {\n  order: -1;\n  margin-right: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
