import { Button, Drawer } from 'antd';
import { useState, useContext } from 'react';
import { Menu } from 'antd';
import { useSelector } from 'react-redux';
import { AuthContext } from '../../context/AuthContext';
import './index.css';

const DrawerProfile = (props) => {
  const { menuItems } = props;
  const auth = useContext(AuthContext);
  const userInfo = useSelector((state) => state.userInfo.userState);
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const onExit = () => {
    auth.logout();
    window.location.reload();
  }
  return (
    <>
      <div className='profileButton'>
        <Button onClick={showDrawer}
          className='profileClick'>
          {userInfo.name ? userInfo.name : userInfo.email}
        </Button>
      </div>
      <Drawer title="Меню"
        placement="right"
        onClose={onClose}
        open={open}
        bodyStyle={{
          backgroundColor: '#001529',
        }}
        footerStyle={{
          backgroundColor: '#001529'
        }}
        footer={<Button type='primary' onClick={onExit} style={{ left: '80%' }}>Выйти</Button>}>
        <Menu
          theme='dark'
          mode="inline"
          items={menuItems}
        />
      </Drawer>
    </>
  );
}

export default DrawerProfile;