import React, { useState, useEffect } from "react";
import { Button, Form, Input, Modal, Progress } from 'antd';
import { useHttp } from "../../../hooks/http.hook";
import { off, on } from "../../../socket";

const MapGetterForm = (params) => {
  const { request } = useHttp();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [submittable, setSubmittable] = useState(false);
  const [percent, setPercent] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const { infoNotification } = params;
  const values = Form.useWatch([], form);
  const userData = localStorage.getItem('userData');

  useEffect(() => {
    form
      .validateFields()
      .then(() => { setSubmittable(true) }, () => { setSubmittable(false) })
  }, [values]);

  useEffect(() => {
    on('webpStatus', (data) => {
      if (!showProgress) setShowProgress(true);
      setPercent(data.percent);
      console.log(`Загружено ${data.currentFine} файлов. Всего ${data.total}`);
      if (data.currentFine === data.total) off('webpStatus');
    })
    on('webpComplete', () => {
      console.log('webpComplete');
      setShowProgress(false);
      infoNotification('Загрузка завершена!');
    })
  }, [])

  const showModal = () => {
    setOpen(true);
  };
  const handleOk = async () => {
    let token = null;
    if (userData) {
      token = await JSON.parse(userData).token;
    }
    fetch('api/app/map/getImageAndConvert', {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify(values)
    })
      // .then((response) => response.status === 400 && infoNotification(`${response.message}` || infoNotification(`Данные успешно отправлены`)));
    form
      .resetFields();
    setOpen(false);
    setShowProgress(true);
  };
  const handleCancel = () => {
    setOpen(false)
  }

  return (
    <>
      <div className='ChartPage__header'>
        {showProgress && <Progress percent={percent}/>}
        <Button type="primary"
          onClick={showModal}
          size="small">
          Загрузка в webP
        </Button>
        <Modal
          title="Координаты квадрата"
          open={open}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Отправить"
          cancelText="Отмена"
          footer={[
            <Button onClick={() => form.resetFields()}>Reset</Button>,
            <Button key="back" onClick={handleCancel}>
              Отмена
            </Button>,
            <Button key="submit" type="primary" disabled={!submittable} onClick={handleOk}>
              Отправить
            </Button>,
          ]}
        >
          <Form form={form} name="validateOnly" layout="vertical" autoComplete="off">
          <Form.Item
              name="startLatitude"
              label="Стартовая широта"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="finalLatitude"
              label="Финальная широта"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="startLongitude"
              label="Стартовая долгота"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="finalLongitude"
              label="Финальная долгота"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="zoomData"
              label="Zoom"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </>
  );
}

export default MapGetterForm;