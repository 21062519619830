import { createSlice } from "@reduxjs/toolkit";

export const createArchiveStateSlice = createSlice({
  name: 'isLoadingArchive',
  initialState: {
    isLoading: false,
  },
  reducers: {
    setLoadingArchive: (state, action) => {
      state.isLoading = !state.isLoading;
    },
  }
});

export const { setLoadingArchive } = createArchiveStateSlice.actions;

export default createArchiveStateSlice.reducer;